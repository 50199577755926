//Dependencies
import {
  useEffect,
  useMemo
} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Chakra
import {
  Heading,
  Flex,
  IconButton,
  Link as Download,
  chakra,
} from '@chakra-ui/react';

//Providers
import { useChart } from '../providers/chart';
import { useView } from '../providers/view';

//Components
import {
  Fullscreen,
  DownloadBlobButton
} from "../components";

//Views
import Full from "../views/full";


export default function ChartHeader() {
  const {activeGridView} = useView();
  const {market, zoom, chartBlob} = useChart();
  const LinkWChakra = chakra(Link);

  const buttonStyles = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: '1',
    width: '40px',
    height: '40px',
    borderRadius: 'sm',
    '&:hover': {
      bgColor: 'gray.200',
      textDecoration: 'none'
    }
  }

  return (
    <Flex className={'chart-header'} justifyContent={'space-between'} alignItems={'center'} pb={'xs'} height={'48px'} width={'100%'} mx={'auto'}>  
      <Heading size={activeGridView.view === 'thumbnail-small' ? 'sm' : 'md'} fontSize={{base: 'xs', md:'sm', xl: 'lg'}}>
          <LinkWChakra to={`${market?.Title}?z=${zoom}`} color={'primary'}>
            {market?.Description} <FontAwesomeIcon icon="fa-light fa-angle-right" />
          </LinkWChakra>
        </Heading>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Fullscreen
          open={<IconButton colorScheme='transparent' color={'text'} aria-label='Maximize Chart' icon={<FontAwesomeIcon icon="fa-sharp fa-solid fa-maximize" size="lg" />} sx={buttonStyles} />}
        >
          <Full market={market} hasHeader={false} hasFooter={false} />
        </Fullscreen>
        <DownloadBlobButton blob={chartBlob} filename={`${market?.Title}-z${zoom}`} />
      </Flex>
    </Flex>
  )
}